import React, { useEffect, useState } from "react";
import AuthComponent from "../auth/Auth";
import firebase from "../../firebase.js";
import SignoutButton from "../auth/SignoutButton";
import Spinner from "react-bootstrap/Spinner";
import NavWrapper from "../nav/NavWrapper";
import Calendar from "./Calendar";
import RecipeBook from "./RecipeBook";

const ifDefined = (value, f) => (value === undefined ? undefined : f(value));
const firestore = firebase.firestore();
const getCurrentUser = () => firebase.auth().currentUser;
const firebaseUserDoc = () => firestore.collection("users").doc(getCurrentUser().uid);
const firebaseDatasetDocument = datasetName => firestore.collection("datasets").doc(datasetName);

const If = NavWrapper.If;

const Loading = () => (
  <div>
    <Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" /> <Spinner animation="grow" variant="info" />{" "}
    <Spinner animation="grow" variant="success" />
  </div>
);

const LoadingWithCaption = ({ caption }) => (
  <div style={{ width: "100%", textAlign: "center", margin: "1rem" }}>
    <Loading />
    <h1>{caption}</h1>
  </div>
);

const LoggedInApp = ({ logOutAction }) => {
  const [datasetHintLoaded, setDatasetHintLoaded] = useState(false);
  const [datasetDoc, setDatasetDoc] = useState(undefined);
  const handleUserDocSnapshot = snapshot => {
    setDatasetHintLoaded(true);
    setDatasetDoc(ifDefined(snapshot.data(), it => firebaseDatasetDocument(it.datasetHint)));
  };
  useEffect(
    () => firebaseUserDoc().onSnapshot(handleUserDocSnapshot),
    [] // Run only on mount
  );

  const [foodCalendarDocs, setFoodCalendarDocs] = useState(undefined);
  const handleFoodCalendarCollectionSnapshot = snapshot => setFoodCalendarDocs(snapshot.docs);
  useEffect(
    () => ifDefined(datasetDoc, it => it.collection("foodCalendar").onSnapshot(handleFoodCalendarCollectionSnapshot)),
    [datasetDoc] // Run when datasetDoc changes
  );

  const [foodDocs, setFoodDocs] = useState(undefined);
  const handleFoodCollectionSnapshot = snapshot => setFoodDocs(snapshot.docs);
  useEffect(
    () => ifDefined(datasetDoc, it => it.collection("foods").onSnapshot(handleFoodCollectionSnapshot)),
    [datasetDoc] // Run when datasetDoc changes
  );

  const [recipeDocs, setRecipeDocs] = useState(undefined);
  const handleRecipeCollectionSnapshot = snapshot => setRecipeDocs(snapshot.docs);
  useEffect(
    () => ifDefined(datasetDoc, it => it.collection("recipes").onSnapshot(handleRecipeCollectionSnapshot)),
    [datasetDoc] // Run when datasetDoc changes
  );

  const [datasetFields, setDatasetFields] = useState(undefined);
  const handleDatasetFieldsSnapshot = snapshot => setDatasetFields(snapshot.data());
  useEffect(() => {
    return ifDefined(datasetDoc, it => it.onSnapshot(handleDatasetFieldsSnapshot));
  }, [datasetDoc]);

  if (!datasetHintLoaded) {
    return <LoadingWithCaption caption={"Hi " + getCurrentUser().displayName + ". Logging in..."} />;
  } else if (datasetDoc === undefined) {
    return <h1>Sorry {getCurrentUser().displayName}. There was an error: no dataset hint</h1>;
  }

  const foodsCollection = datasetDoc.collection("foods");
  const recipesCollection = datasetDoc.collection("recipes");

  const actions = {
    addRecipeBookItem: (id, data) => foodsCollection.doc(id).set(data),
    updateRecipe: (recipeId, updatedRecipe) => recipesCollection.doc(recipeId).set(updatedRecipe),
    addRecipe: newRecipe => recipesCollection.add(newRecipe),
  };

  if (foodCalendarDocs === undefined || foodDocs === undefined || recipeDocs === undefined || datasetFields === undefined) {
    return <LoadingWithCaption caption={"Hi " + getCurrentUser().displayName + ". Loading..."} />;
  } else {
    return (
      <NavWrapper title={"Food (" + datasetDoc.id + ")"}>
        <If tabName="Calendar" ifSelectedShow={() => <Calendar foodCalendarDocs={foodCalendarDocs} />} />
        <If tabName="Recipes" default ifSelectedShow={() => <RecipeBook foodDocs={foodDocs} recipeDocs={recipeDocs} actions={actions} />} />
        <SignoutButton onClick={logOutAction} />
      </NavWrapper>
    );
  }
};

const App = () => (
  <AuthComponent>
    <LoggedInApp />
  </AuthComponent>
);

export default App;
