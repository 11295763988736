import React, { useState } from "react";
import { Button, Card, CardColumns } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import useRecipeModal from "./RecipeModal";

const Amount = ({ amount }) => (
  <span>
    {amount.quantity}
    {!!amount.unit ? amount.unit : "x"}
  </span>
);

const IngredientsList = ({ recipeId, recipe, titleLookup, title, ingredientDocs, actions }) => {
  const existingIngredients = recipe.ingredients || [];
  return (
    <div>
      <span>To make {!!recipe.makes ? <Amount amount={recipe.makes} /> : "some " + title}:</span>
      {!!recipe.draft ? (
        <>
          {" "}
          <Badge variant={"warning"}>Draft</Badge>
        </>
      ) : null}{" "}
      <EditRecipeButton ingredientDocs={ingredientDocs} recipeId={recipeId} recipe={recipe} actions={actions} />
      <ul>
        {existingIngredients.map(ingredient => (
          <li key={ingredient.id}>
            {!!ingredient.amount ? (
              <>
                <Amount amount={ingredient.amount} /> {titleLookup.get(ingredient.id)}
              </>
            ) : (
              titleLookup.get(ingredient.id)
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Food = ({ id, food, titleLookup, recipeDocs, ingredientDocs, actions, isExpandedFood, setExpandedFood }) => {
  const recipes = recipeDocs.filter(recipeDoc => recipeDoc.data().foodId === id);
  const collapse = () => {
    if (isExpandedFood(id)) {
      setExpandedFood(id, false);
    }
  };
  const expand = () => {
    if (!isExpandedFood(id)) {
      setExpandedFood(id, true);
    }
  };
  return (
    <Card onClick={expand}>
      <Card.Body>
        <Card.Title style={{ cursor: "pointer" }} onClick={collapse}>
          {food.title}
        </Card.Title>
        {isExpandedFood(id) ? (
          <>
            {recipes.map(recipeDoc => (
              <IngredientsList
                key={recipeDoc.id}
                recipeId={recipeDoc.id}
                recipe={recipeDoc.data()}
                titleLookup={titleLookup}
                title={food.title}
                ingredientDocs={ingredientDocs}
                actions={actions}
              />
            ))}
            <AddRecipeButton ingredientDocs={ingredientDocs} foodId={id} actions={actions} />
          </>
        ) : (
          <RecipeCount count={recipes.length} />
        )}
      </Card.Body>
    </Card>
  );
};

const RecipeCount = ({ count }) => {
  if (count === 0) {
    return null;
  } else if (count === 1) {
    return <span style={{ fontStyle: "italic" }}>1 recipe...</span>;
  } else {
    return <span style={{ fontStyle: "italic" }}>{count} recipes...</span>;
  }
};

const AddRecipeButton = ({ ingredientDocs, foodId, actions }) => {
  const newRecipeTemplate = { draft: true, foodId: foodId };
  const [modal, handleShow] = useRecipeModal(ingredientDocs, newRecipeTemplate, newRecipe => actions.addRecipe(newRecipe), true);
  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Add Recipe
      </Button>
      {modal}
    </>
  );
};

const EditRecipeButton = ({ ingredientDocs, recipeId, recipe, actions }) => {
  const [modal, handleShow] = useRecipeModal(ingredientDocs, recipe, updatedRecipe => actions.updateRecipe(recipeId, updatedRecipe), false);
  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Edit
      </Button>
      {modal}
    </>
  );
};

const Foods = ({ foodDocs, recipeDocs, titleLookup, ingredientDocs, actions, isExpandedFood, setExpandedFood }) => (
  <CardColumns>
    {foodDocs
      .sort((first, second) => first.data().title.localeCompare(second.data().title))
      .map(doc => (
        <Food
          key={doc.id}
          id={doc.id}
          food={doc.data()}
          titleLookup={titleLookup}
          recipeDocs={recipeDocs}
          ingredientDocs={ingredientDocs}
          actions={actions}
          isExpandedFood={isExpandedFood}
          setExpandedFood={setExpandedFood}
        />
      ))}
  </CardColumns>
);

const applySuffix = (idPrefix, suffix) => (suffix > 1 ? idPrefix + "_" + suffix : idPrefix);
const generateId = (title, titleLookup) => {
  const idPrefix = title
    .toUpperCase()
    .replace(/ +/g, "_")
    .replace(/\W/g, "");
  let suffix = 1;
  while (!!titleLookup.get(applySuffix(idPrefix, suffix))) {
    suffix++;
  }
  return applySuffix(idPrefix, suffix);
};

const AddFoodForm = ({ toggleShowAddForm, titleLookup, actions, defaultValues }) => {
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState("");
  const id = generateId(title, titleLookup);

  const reset = () => {
    setValidated(false);
    setTitle("");
  };

  const handleSubmit = event => {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      actions.addRecipeBookItem(id, { ...defaultValues, title: title });
      reset();
    }
  };

  const handleCancel = () => {
    reset();
    toggleShowAddForm();
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control value={title} onChange={event => setTitle(event.target.value)} required type="text" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Add
      </Button>{" "}
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <span style={{ fontSize: "80%" }}> {id}</span>
    </Form>
  );
};

const FoodsSection = ({
  titlePlural,
  titleSingle,
  foodDocs,
  recipeDocs,
  titleLookup,
  actions,
  defaultValues = {},
  ingredientDocs,
  isExpandedFood,
  setExpandedFood,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const toggleShowAddForm = () => setShowAddForm(!showAddForm);
  return (
    <div>
      <h1>
        {titlePlural}{" "}
        <ButtonGroup>
          <RadioButton toggled={showAddForm} toggle={toggleShowAddForm}>
            Add {titleSingle}
          </RadioButton>
        </ButtonGroup>
      </h1>
      <HidingAlert visible={showAddForm}>
        <h2>Add {titleSingle}:</h2>
        <AddFoodForm toggleShowAddForm={toggleShowAddForm} titleLookup={titleLookup} actions={actions} defaultValues={defaultValues} />
      </HidingAlert>
      <Foods
        foodDocs={foodDocs}
        recipeDocs={recipeDocs}
        titleLookup={titleLookup}
        ingredientDocs={ingredientDocs}
        actions={actions}
        isExpandedFood={isExpandedFood}
        setExpandedFood={setExpandedFood}
      />
    </div>
  );
};

const makeTitleLookupMap = recipeBookDocs => {
  const titleLookup = new Map();
  recipeBookDocs.forEach(doc => titleLookup.set(doc.id, doc.data().title));
  return titleLookup;
};

const RecipeBook = ({ foodDocs, recipeDocs, actions }) => {
  const titleLookup = makeTitleLookupMap(foodDocs);
  const mealDocs = foodDocs.filter(doc => !!doc.data().meal);
  const ingredientDocs = foodDocs.filter(doc => !doc.data().meal);

  const count = foodDocs.length;
  const [allSelected, setAllSelected] = useState(false);
  const [expansionExceptions, setExpansionExceptions] = useState([]);
  const isExpandedFood = foodId => allSelected ^ expansionExceptions.includes(foodId);
  const setExpandedFood = (foodId, expanded) =>
    setExpansionExceptions(allSelected ^ expanded ? [...expansionExceptions, foodId] : expansionExceptions.filter(id => id !== foodId));

  return (
    <div>
      <ButtonGroup>
        <Button
          variant={(allSelected && expansionExceptions.length === 0) || (!allSelected && expansionExceptions.length === count) ? "primary" : "outline-primary"}
          onClick={() => {
            setAllSelected(true);
            setExpansionExceptions([]);
          }}
        >
          Expand all
        </Button>
        <Button
          variant={(!allSelected && expansionExceptions.length === 0) || (allSelected && expansionExceptions.length === count) ? "primary" : "outline-primary"}
          onClick={() => {
            setAllSelected(false);
            setExpansionExceptions([]);
          }}
        >
          Collapse all
        </Button>
      </ButtonGroup>
      <hr />
      <FoodsSection
        titlePlural="Meals"
        titleSingle="Meal"
        defaultValues={{ meal: true }}
        foodDocs={mealDocs}
        ingredientDocs={ingredientDocs}
        recipeDocs={recipeDocs}
        titleLookup={titleLookup}
        actions={actions}
        isExpandedFood={isExpandedFood}
        setExpandedFood={setExpandedFood}
      />
      <FoodsSection
        titlePlural="Ingredients"
        titleSingle="Ingredient"
        foodDocs={ingredientDocs}
        ingredientDocs={ingredientDocs}
        recipeDocs={recipeDocs}
        titleLookup={titleLookup}
        actions={actions}
        isExpandedFood={isExpandedFood}
        setExpandedFood={setExpandedFood}
      />
    </div>
  );
};

const RadioButton = ({ toggled, toggle, children, variant = "primary" }) => (
  <Button variant={toggled ? variant : "outline-" + variant} onClick={toggle}>
    {children}
  </Button>
);

const HidingAlert = ({ visible, children, variant = "primary" }) => (
  <Alert variant={variant} style={{ display: visible ? "block" : "none" }}>
    {children}
  </Alert>
);

export default RecipeBook;
