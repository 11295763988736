import React, { useState } from "react";
import months from "../dateFormatting/monthNames";

const Calendar = ({ foodCalendarDocs }) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const [currentTimer, setCurrentTimer] = useState(undefined);
  const recentScroll = currentTimer !== undefined;

  const [firstDate, setFirstDate] = useState(() => {
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() - 1) - 21);
  });
  const moveBy = count => {
    setFirstDate(new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + count));
    currentTimer !== undefined && clearTimeout(currentTimer);
    setCurrentTimer(setTimeout(() => setCurrentTimer(undefined), 500));
  };
  const intoTheFuture = () => moveBy(7);
  const intoThePast = () => moveBy(-7);

  const dummyMealData = foodCalendarDocs.map(doc => {
    const obj = doc.data();
    return {
      date: obj.plannedDate.toDate(),
      available: !!obj.available,
      name: obj.title,
    };
  });

  const allDates = Array(7 * 6)
    .fill()
    .map((x, i) => new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + i));

  const getDateString = (isFirstOfMonth, date, isToday) => {
    if (isFirstOfMonth) {
      if (recentScroll) {
        return (
          <span>
            {months[date.getMonth()]}
            <br />
            {date.getFullYear()}
          </span>
        );
      } else {
        return date.getDate() + " " + months[date.getMonth()];
      }
    } else {
      return isToday ? "Today" : date.getDate();
    }
  };

  return (
    <div
      className={classNames({ "calendar-container": true, "calendar-highlight": recentScroll })}
      onWheel={event => {
        if (event.deltaY > 0) {
          intoTheFuture();
        } else if (event.deltaY < 0) {
          intoThePast();
        }
      }}
    >
      <span className="day-name">Mon</span>
      <span className="day-name">Tue</span>
      <span className="day-name">Wed</span>
      <span className="day-name">Thu</span>
      <span className="day-name">Fri</span>
      <span className="day-name">Sat</span>
      <span className="day-name">Sun</span>
      {allDates.map(date => {
        const isToday = date.getTime() === today.getTime();
        const isFirstOfMonth = date.getDate() === 1;
        return (
          <div className={classNames({ day: true, special: isToday || isFirstOfMonth })} key={date}>
            <div className="day-header">{getDateString(isFirstOfMonth, date, isToday)}</div>
            {dummyMealData
              .filter(meal => meal.date.getTime() === date.getTime())
              .map((meal, index) => (
                <div key={index} className={meal.available ? "meal--available" : "meal--unavailable"}>
                  {meal.name}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

const classNames = obj =>
  Object.keys(obj)
    .filter(key => obj[key])
    .join(" ");

export default Calendar;
