import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

const makeController = (recipeModel, setRecipeModel) => {
  const currentIngredients = !!recipeModel.ingredients ? recipeModel.ingredients : [];
  const withNewIngredient = id => [...currentIngredients, { id: id }];
  const withChangedIngredientId = (index, newId) => {
    const ingredientsCopy = [...currentIngredients];
    ingredientsCopy[index] = { ...ingredientsCopy[index], id: newId };
    return ingredientsCopy;
  };
  const withChangedAmount = (index, newAmount) => {
    const ingredientsCopy = [...currentIngredients];
    ingredientsCopy[index] = { ...ingredientsCopy[index], amount: newAmount };
    return ingredientsCopy;
  };
  const updateModel = update => setRecipeModel({ ...recipeModel, ...update });
  return {
    newIngredient: id => updateModel({ ingredients: withNewIngredient(id) }),
    setIngredientId: (index, newId) => updateModel({ ingredients: withChangedIngredientId(index, newId) }),
    setAmount: (index, newAmount) => updateModel({ ingredients: withChangedAmount(index, newAmount) }),
    setMakes: newAmount => updateModel({ makes: newAmount }),
    setDraft: isDraft => updateModel({ draft: isDraft }),
  };
};

const RecipeModal = ({ show, handleClose, ingredientDocs, isNew, existingRecipe, saveCallback }) => {
  const [recipeModel, setRecipeModel] = useState(undefined);
  useEffect(() => setRecipeModel(existingRecipe), [existingRecipe, show]);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{!!isNew ? "Add " : "Edit "}Recipe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!recipeModel ? <RecipeModalContent ingredientDocs={ingredientDocs} recipeModel={recipeModel} setRecipeModel={setRecipeModel} /> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            saveCallback(recipeModel);
            handleClose();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AmountField = ({ amount, setAmount }) => {
  const formattedAmount = amount ? (!!amount.quantity ? amount.quantity : "") + (!!amount.unit ? amount.unit : "") : "";
  return (
    <Form.Control
      value={formattedAmount}
      onChange={event => {
        const formattedNewValue = event.target.value;
        const newValueGroups = formattedNewValue.match(/(?<quantity>[0-9]*)(?<unit>.*)/).groups;
        const newQuantity = newValueGroups.quantity.length > 0 ? newValueGroups.quantity : null;
        const newUnit = newValueGroups.unit.length > 0 ? newValueGroups.unit : null;
        const newAmount = !!newQuantity || !!newUnit ? { quantity: newQuantity, unit: newUnit } : null;
        setAmount(newAmount);
      }}
      type="text"
    />
  );
};

const RecipeModalContent = ({ ingredientDocs, recipeModel, setRecipeModel }) => {
  const ingredients = recipeModel.ingredients || [];

  const recipeController = makeController(recipeModel, setRecipeModel);

  return (
    <div>
      <Form noValidate onSubmit={() => {}}>
        <Form.Group>
          <Form.Label>Makes:</Form.Label>
          <AmountField amount={recipeModel.makes} setAmount={newAmount => recipeController.setMakes(newAmount)} />
        </Form.Group>
        <Form.Group>
          <Form.Check checked={recipeModel.draft} onChange={event => recipeController.setDraft(event.target.checked)} type="checkbox" label="Draft" />
        </Form.Group>
      </Form>
      <Table borderless hover size="sm">
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Quantity</th>
            <th style={{ width: "50%" }}>Ingredient</th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient, index) => (
            <tr key={ingredient.id}>
              <td>
                <AmountField amount={ingredient.amount} setAmount={newAmount => recipeController.setAmount(index, newAmount)} />
              </td>
              <td>
                <ChooseIngredient
                  ingredientDocs={ingredientDocs}
                  existingIngredientIds={ingredients.map(ingredient => ingredient.id)}
                  id={ingredient.id}
                  setId={newId => recipeController.setIngredientId(index, newId)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td />
            <td>
              <ChooseIngredient
                ingredientDocs={ingredientDocs}
                existingIngredientIds={ingredients.map(ingredient => ingredient.id)}
                id={undefined}
                setId={recipeController.newIngredient}
                placeholder="Add new"
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const ChooseIngredient = ({ ingredientDocs, existingIngredientIds, id, setId, placeholder }) => {
  const showIngredient = doc => doc.id === id || !existingIngredientIds.includes(doc.id);
  const options = ingredientDocs.filter(showIngredient).map(doc => ({ value: doc.id, label: doc.data().title }));
  const value = options.find(option => option.value === id);
  return <Select value={!!value ? value : null} onChange={newValue => setId(newValue.value)} options={options} placeholder={placeholder} />;
};

const useRecipeModal = (ingredientDocs, existingRecipe, saveCallback, isNew) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modal = (
    <RecipeModal
      show={show}
      handleClose={handleClose}
      ingredientDocs={ingredientDocs}
      isNew={isNew}
      existingRecipe={existingRecipe}
      saveCallback={saveCallback}
    />
  );
  return [modal, handleShow];
};

export default useRecipeModal;
