import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDSbbAULhuVmBbc5arkleQ7uaYNfns-z6s",
  authDomain: "lorna-and-steves-food.firebaseapp.com",
  databaseURL: "https://lorna-and-steves-food.firebaseio.com",
  projectId: "lorna-and-steves-food",
  storageBucket: "lorna-and-steves-food.appspot.com",
  messagingSenderId: "1084924847095",
};
firebase.initializeApp(config);

export default firebase;
