import React from "react";
import FontAwesome from "react-fontawesome";
import Button from "react-bootstrap/Button";

const SignoutButton = ({ onClick }) => (
  <Button style={{ margin: "0.1rem" }} onClick={onClick} variant="danger">
    <FontAwesome name="times" />
    &nbsp;Sign-out
  </Button>
);

export default SignoutButton;
